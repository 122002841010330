<template>
  <v-card
    class="mx-5 pa-0 elevation-0"
    width="180px"
    elevation="0"
    @click="$router.push({name: 'books.show', params: {slug: product.slug}})"
  >
    <div class="img__wrapper" >
<!--      :style="'height:' +( $vuetify.breakpoint.mdAndUp ? '270px' : '260px')"-->
    <img
      :src="product.image_url"
      @click="handleCardClick"
      class="my-auto"
    />
<!--      width="170px"-->


      <!--      <template v-slot:placeholder>-->
<!--        <v-row-->
<!--          class="fill-height ma-0"-->
<!--          align="center"-->
<!--          justify="center"-->
<!--        >-->
<!--          <v-progress-circular-->
<!--            indeterminate-->
<!--            color="grey"-->
<!--          ></v-progress-circular>-->
<!--        </v-row>-->
<!--      </template>-->
<!--    </v-img>-->
    </div>
    <v-card-title @click="handleCardClick" class="cut-text pa-0">{{ product.name }}</v-card-title>
    <v-card-text class="pa-0"><del>was R <span>{{ (parseFloat(product.price) + parseFloat(product.price) * .2).toFixed(2) }}</span></del>
    </v-card-text>
    <v-card-subtitle class="pa-0 price">
      <div>
      R {{ product.price }}
      </div>
      <div class="mt-1">
      <v-chip
        color="#ddcecd"
        label
        small
        v-if="product.is_bestseller"
      >
        <v-icon left small>
          mdi-medal-outline
        </v-icon>
        bestseller
      </v-chip>
      </div>
    </v-card-subtitle>

    <!--
        <v-card-subtitle>
          <v-rating
            v-model="product.rating"
            background-color="orange lighten-3"
            color="orange"
            small
            readonly
          ></v-rating>
        </v-card-subtitle>


    <v-card-actions class="d-flex justify-space-between">
      <v-btn
        :class="{favorite: isFavorite}"
        text
        @click="handleFavorite"
      >
        <v-icon>mdi-heart</v-icon>
      </v-btn>

      <v-btn
        class="cursor-unset"
        color="orange"
        text
        @click="handleCardClick"
      >
        Details
      </v-btn>
    </v-card-actions>
    -->
  </v-card>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ProductCardSlide",
  props: ['product'],
  data() {
    return {
      isFavorite: false
    }
  },
  computed: {
    ...mapState({
      favorites: state => {
        return state.favorites.items;
      },
    }),
  },
  watch: {
    favorites(newFavorites, oldFavorites) {
      this.defineIsInFavorites()
    }
  },
  mounted() {
    this.defineIsInFavorites()
  },
  methods: {
    handleCardClick() {
      this.$router.push({name: 'books.show', params: {slug: this.product.slug}})
    },
    async handleFavorite() {
      this.isFavorite
        ? await this.$store.dispatch('favorites/removeFromFavorites', {product: this.product}, {root: true})
        : await this.$store.dispatch('favorites/addToFavorites', {product: this.product}, {root: true})
    },
    defineIsInFavorites() {
      for (let i = 0; i < this.favorites.length; i++) {
        if (this.favorites[i].id === this.product.id) {
          this.isFavorite = true
          return
        }
      }
      this.isFavorite = false
    }
  }
}
</script>

<style scoped lang="scss">
.price {
  font-size: 1.2em;
  font-weight: bold;
}
::v-deep .img__wrapper {
  &, &.v-image{
  max-width: 272px;
  height: 270px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img{
    object-fit: contain;
    max-width: 170px;
    width: 90%;
  }
  .v-image__image {
    object-fit: contain;
    max-width: 272px;
  }
  }
}
.cut-text {
  width: 100%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1em;
}

.card {
  text-align: center;
}

.w-100 {
  width: 100%;
}
</style>
