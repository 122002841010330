<template>
 <v-container class="pa-4 pa-md-2">
  <v-row no-gutters>
   <v-col cols="12">
	<state-indicate ref="indicate" v-slot:default="{isLoading,usePromise}">
	 <v-row class="d-flex flex-row align-center">
	  <v-col class="flex-grow-1" cols="2"><h2>Products</h2></v-col>
	  <v-col cols="4" class="flex-grow-1"><span class="fz-08" v-if="meta.total">{{ meta.total }} Item(s)</span></v-col>
	  <v-col class="flex-grow-1" offset="3" cols="3" align="end">
	   <v-row class="d-flex flex-row align-baseline">
		<v-col cols="6" class="flex-grow-1"><span class="fz-08 d-none">Sort By:</span></v-col>
		<v-col cols="6" class="flex-grow-1">
		 <v-select
       v-model="sortBy"
		   v-if="!isLoading"
		   :items="sortSelects"
       item-text="name"
       item-value="sortBy"
		   label="Sort By"
		   dense
		   outlined
		 >
		 </v-select>
		</v-col>
	   </v-row>
	  </v-col>
	 </v-row>
	 <v-row class="products_filter-container">

	  <v-col md="4" lg="3" sm="4" cols="12">
	   <state-indicate ref="tags-indicate" v-slot:default="{isLoading,usePromise}">
		<v-list class="tags-list">
		 <v-list-item class="tags-list-item pa-0 pb-5">
		  <h5>All products</h5>
		 </v-list-item>


		 <v-skeleton-loader
		   v-if="isLoading"
		   type="list-item-avatar@6"
		 >

		 </v-skeleton-loader>


		 <template v-if="!isLoading">
		  <v-list-item
			v-if="!tag.children"
			v-for="(tag, i) in tags.slice(0, showMore.tags ? tags.length : 6)" :key="tag.id"
			class="tags-list-item pa-0"
			@click="filter.tag = tag.id"
		  >
		   <v-list-item-avatar class="ma-0 pa-0">
			<v-img contain max-width="20px" height="20px" :src="tag.image"></v-img>
		   </v-list-item-avatar>
		   <v-list-item-title v-text="tag.name"/>
		   <v-chip x-small class="mr-2">
			{{ tag.products_count }}
		   </v-chip>
		  </v-list-item>

		  <v-list-group
			v-else
			:key="tag.id"
			no-action
			append-icon="mdi-plus"
			:value="false"
		  >
		   <template v-slot:activator>
			<v-list-item-title>{{ tag.name }}</v-list-item-title>
		   </template>

		   <v-list-item
			 v-for="child in tag.children"
			 :key="child.name"
		   >
			<v-list-item-title>{{ child.name }}</v-list-item-title>
		   </v-list-item>
		  </v-list-group>
		 </template>



		</v-list>
		<div v-if="!isLoading"
			 class="show_more"
			 @click="showMore.tags = !showMore.tags">
		 <v-icon>{{ showMore.tags ? 'mdi-minus-circle-outline' : 'mdi-plus-circle-outline' }}</v-icon>
		 Show more
		</div>

	   </state-indicate>
	   <state-indicate ref="formats-indicate" v-slot:default="{isLoading,usePromise}">


		<v-list-item-group
		  class="pt-10"
		  v-model="filter.format"
		  multiple
		  active-class=""
		>
		 <v-list class="tags-list">
		  <div class="tags-list-item pa-0 pb-5">
		   <h5>Format</h5>
		  </div>
		  <v-skeleton-loader
			v-if="isLoading"
			type="table-heading@6"
		  >

		  </v-skeleton-loader>
		  <template v-if="!isLoading">
		  	<v-list-item
			v-for="(format, i) in formats.slice(0, showMore.formats ? formats.length : 6)" :key="i"
			class="tags-list-item pa-0"
			:value="format"
		  >
		   <template v-slot:default="{ active }">
			<v-list-item-action class="pl-2 mr-3">
			 <v-checkbox :input-value="active"></v-checkbox>
			</v-list-item-action>
			<v-list-item-title class="font-weight-medium" v-text="format"/>
		   </template>
		  </v-list-item>
		  </template>
		 </v-list>
		 <div v-if="!isLoading"
			  class="show_more"
			  @click="showMore.formats = !showMore.formats">
		  <v-icon>{{ showMore.formats ? 'mdi-minus-circle-outline' : 'mdi-plus-circle-outline' }}</v-icon>
		  Show more
		 </div>
		</v-list-item-group>

	   </state-indicate>

	   <state-indicate ref="authors-indicate" v-slot:default="{isLoading,usePromise}">
		<v-list-item-group
		  class="pt-10"
		  v-model="filter.author"
		  multiple
		  active-class=""
		>
		 <v-list class="tags-list">
		  <div class="tags-list-item pa-0 pb-5">
		   <h5>Author</h5>
		  </div>
		  <v-skeleton-loader
			v-if="isLoading"
			type="table-heading@6"
		  >

		  </v-skeleton-loader>
		  <template v-if="!isLoading">
		  	<v-list-item
			v-for="(author, i) in authors.slice(0, showMore.authors ? authors.length : 6)" :key="i"
			class="tags-list-item pa-0"
			:value="author"
		  >
		   <template v-slot:default="{ active }">
			<v-list-item-action class="pl-2 mr-3">
			 <v-checkbox :input-value="active"></v-checkbox>
			</v-list-item-action>
			<v-list-item-title class="font-weight-medium" v-text="author"/>
		   </template>
		  </v-list-item>
		  </template>
		 </v-list>
		 <div v-if="!isLoading"
			  class="show_more"
			  @click="showMore.authors = !showMore.authors">
		  <v-icon>{{ showMore.authors ? 'mdi-minus-circle-outline' : 'mdi-plus-circle-outline' }}</v-icon>
		  Show more
		 </div>
		</v-list-item-group>

	   </state-indicate>

	   <state-indicate ref="languages-indicate" v-slot:default="{isLoading,usePromise}">
	   <v-list-item-group
		 class="pt-10"
		 v-model="filter.language"
		 multiple
		 active-class=""
	   >
		<v-list class="tags-list">
		 <div class="tags-list-item pa-0 pb-5">
		  <h5>Language</h5>
		 </div>
		 <v-skeleton-loader
		   v-if="isLoading"
		   type="table-heading@6"
		 >

		 </v-skeleton-loader>
		 <template v-if="!isLoading">
		 	<v-list-item
		   v-for="(language, i) in languages.slice(0, showMore.languages ? languages.length : 6)" :key="i"
		   class="tags-list-item pa-0"
		   :value="language"
		 >
		  <template v-slot:default="{ active }">
		   <v-list-item-action class="pl-2 mr-3">
			<v-checkbox :input-value="active"></v-checkbox>
		   </v-list-item-action>
		   <v-list-item-title class="font-weight-medium" v-text="language"/>
		  </template>
		 </v-list-item>
		 </template>
		</v-list>
		<div v-if="!isLoading"
			 class="show_more"
			 @click="showMore.languages = !showMore.languages">
		 <v-icon>{{ showMore.languages ? 'mdi-minus-circle-outline' : 'mdi-plus-circle-outline' }}</v-icon>
		 Show more
		</div>
	   </v-list-item-group>

	   </state-indicate>


	   <!--            </v-navigation-drawer>-->
	  </v-col>
	  <v-col md="8" lg="9" sm="8" cols="12">

	   <v-progress-linear
		 indeterminate
		 :style="{opacity: isLoading ? 1 : 0}"
	   ></v-progress-linear>

	   <v-row>
		<v-col cols="12" lg="3" md="4" sm="5"
			   class="py-5"
			   v-for="i in 12"
			   v-if="isLoading"
			   :key="i + Math.random()"
		>
		 <v-skeleton-loader
		   class="mx-auto"
		   type="card"
		   width="200"
		 ></v-skeleton-loader>
		</v-col>

		<v-col
		  cols="6"
		  lg="3"
		  md="4"
		  sm="6"
		  v-for="product in products"
		  :key="product.id"
		  class="py-5"
		>
		 <product-card-slide :product="product"/>
		</v-col>
	   </v-row>

	   <v-pagination
		 v-if="!isLoading"
		 v-model="page"
		 :length="meta.last_page"
		 circle
		 :total-visible="7"
	   ></v-pagination>
	  </v-col>
	 </v-row>
	</state-indicate>
   </v-col>
  </v-row>
 </v-container>
</template>

<script>
  import ApiTags from '../api/tags';
  import Product from '@/models/Product';
  import ProductCard from '../components/ProductCard';
  import ProductCardSlide from '../components/products/ProductCardSlide';
  import ProductApi from '../api/products';

  import StateIndicate from '../components/utils/StateIndicate';

  export default {
    name: 'Products',
    props: ['tagId'],
    data() {
      return {
        search: '',
        sortSelects: [
            {
                name: 'Price Low-High',
                sortBy: 'price',
            },
            {
                name: 'Price High-Low',
                sortBy: '-price',
            },
            {
                name: 'Title A-Z',
                sortBy: 'name'
            },
            {
                name: 'Title Z-A',
                sortBy: '-name'
            }
        ],
        sortBy: '',
        tags: [],
        products: [],
        formats: [],
        authors: [],
        languages: [],
        filter: {format: [], author: [], language: [], tag: ''},
        showMore: {tags: false, formats: false, authors: false, languages: false},
        meta: {},
        page: 1,
        perPage: 10,
        selectedTag: null,
      };
    },
    watch: {
      async page() {
        this.$refs.indicate.usePromise(
            this.fetchProducts(),
        );
      },
      'filter.tag'(val, oldVal) {
          this.resetFilters()
      },
      filter: {
        handler: function(val, oldVal) {
          var queryParams = new URLSearchParams(window.location.search);
          if (val.tag) {
            queryParams.set("tag",val.tag);
            history.replaceState(null, null, "?"+queryParams.toString());
          } else {
            queryParams.delete("tag");
            history.replaceState(null, null, "?"+queryParams.toString());
          }
          this.$refs.indicate.usePromise(
              this.fetchProducts(),
          );
        },
        deep: true,
      },
      sortBy(val, oldVal) {
        this.fetchProducts()
      }
    },

    async mounted() {

      if (this.$route.query.tag) {
        this.filter.tag = this.$route.query.tag;
	  }

      this.$refs.indicate.usePromise(
          this.fetchProducts(),
      );
      this.$refs['authors-indicate'].usePromise(
          this.fetchAuthors()
      );

      this.$refs['languages-indicate'].usePromise(
          this.fetchLanguages()
      );

      this.$refs['formats-indicate'].usePromise(
          this.fetchFormats()
      );

      this.$refs['tags-indicate'].usePromise(
          this.fetchTags(),
      );
    },

    methods: {
      resetFilters() {
        this.sortBy = ''
        this.page = 1
        this.filter.format = []
        this.filter.author = []
        this.filter.language = []
      },
      fetchTags() {
        return ApiTags.list().then(ApiResponse => {
          let tags = ApiResponse.getData();
          let images = [
            require('@/assets/images/tags/academic-01.svg'),
            require('@/assets/images/tags/architecture-01.svg'),
            require('@/assets/images/tags/art-01.svg'),
            require('@/assets/images/tags/child-01.svg'),
            require('@/assets/images/tags/craft-01.svg'),
            require('@/assets/images/tags/fiction-01.svg'),
          ];
          let noIcon = require('@/assets/images/logomark-beige-01.svg');
          tags.map((v, i) => {
            v.image = images[i] || noIcon;
          });
          this.tags = tags;
        });
      },
      fetchProducts() {
        return Product.page(this.page).
        limit(12).
        where('tags.id', this.filter.tag).
        where('author', this.filter.author.join(',')).
        where('language', this.filter.language.join(',')).
        where('format', this.filter.format.join(',')).
        orderBy(this.sortBy).
        get().
        then(ApiResponse => {
          this.products = ApiResponse.getData();
          this.meta = ApiResponse.getMeta();
        });
      },
      fetchLanguages() {
        return ProductApi.languagesList().then(ApiResponse => {
          this.languages = ApiResponse.getData();
        });
      },
      fetchFormats() {
        return ProductApi.formatsList().then(ApiResponse => {
          this.formats = ApiResponse.getData();
        });
      },
      fetchAuthors() {
        return ProductApi.authorsList().then(ApiResponse => {
          this.authors = ApiResponse.getData();
        });
      },
      handleSearch() {
        this.$router.push({name: 'search', params: {search: this.search}});
      },
    },

    components: {
      ProductCardSlide,
      ProductCard, StateIndicate,
    },
  };
</script>

<style lang="scss">
 @import "./src/assets/scss/pages/products/products";
</style>

<style scoped lang="scss">


 .tags-list {
  .tags-list-item {
   min-height: unset;
   padding: .5em 2em;
   max-height: 27px;

   .v-list-item__title {
	text-transform: capitalize;
	font-size: .8em;
	font-weight: bolder;
   }
  }
 }

 .fz-08 {
  font-size: .8em;
 }

 .show_more {
  color: var(--color-blue);
  text-align: center;
  font-size: .8em;
  font-weight: bolder;
  cursor: pointer;
 }

 .v-text-field--outlined.v-input--dense .v-label {
  font-size: 10px;
 }
</style>
