import axios from "axios";

export default {
    update(id, data) {
        return axios.post(`/api/products/${id}`, data)
    },
    store(data) {
        return axios.post(`/api/products`, data)
    },
    delete(id) {
        return axios.delete(`/api/products/${id}`)
    },
    authorsList() {
        return axios.get('/api/products/authors/list')
    },
    formatsList() {
        return axios.get('/api/products/formats/list')
    },
    languagesList() {
        return axios.get('/api/products/languages/list')
    },
}
