var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4 pa-md-2"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('state-indicate',{ref:"indicate",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
var usePromise = ref.usePromise;
return [_c('v-row',{staticClass:"d-flex flex-row align-center"},[_c('v-col',{staticClass:"flex-grow-1",attrs:{"cols":"2"}},[_c('h2',[_vm._v("Products")])]),_c('v-col',{staticClass:"flex-grow-1",attrs:{"cols":"4"}},[(_vm.meta.total)?_c('span',{staticClass:"fz-08"},[_vm._v(_vm._s(_vm.meta.total)+" Item(s)")]):_vm._e()]),_c('v-col',{staticClass:"flex-grow-1",attrs:{"offset":"3","cols":"3","align":"end"}},[_c('v-row',{staticClass:"d-flex flex-row align-baseline"},[_c('v-col',{staticClass:"flex-grow-1",attrs:{"cols":"6"}},[_c('span',{staticClass:"fz-08 d-none"},[_vm._v("Sort By:")])]),_c('v-col',{staticClass:"flex-grow-1",attrs:{"cols":"6"}},[(!isLoading)?_c('v-select',{attrs:{"items":_vm.sortSelects,"item-text":"name","item-value":"sortBy","label":"Sort By","dense":"","outlined":""},model:{value:(_vm.sortBy),callback:function ($$v) {_vm.sortBy=$$v},expression:"sortBy"}}):_vm._e()],1)],1)],1)],1),_c('v-row',{staticClass:"products_filter-container"},[_c('v-col',{attrs:{"md":"4","lg":"3","sm":"4","cols":"12"}},[_c('state-indicate',{ref:"tags-indicate",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
var usePromise = ref.usePromise;
return [_c('v-list',{staticClass:"tags-list"},[_c('v-list-item',{staticClass:"tags-list-item pa-0 pb-5"},[_c('h5',[_vm._v("All products")])]),(isLoading)?_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar@6"}}):_vm._e(),(!isLoading)?_vm._l((_vm.tags.slice(0, _vm.showMore.tags ? _vm.tags.length : 6)),function(tag,i){return (!tag.children)?_c('v-list-item',{key:tag.id,staticClass:"tags-list-item pa-0",on:{"click":function($event){_vm.filter.tag = tag.id}}},[_c('v-list-item-avatar',{staticClass:"ma-0 pa-0"},[_c('v-img',{attrs:{"contain":"","max-width":"20px","height":"20px","src":tag.image}})],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(tag.name)}}),_c('v-chip',{staticClass:"mr-2",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(tag.products_count)+" ")])],1):_c('v-list-group',{key:tag.id,attrs:{"no-action":"","append-icon":"mdi-plus","value":false},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(_vm._s(tag.name))])]},proxy:true}],null,true)},_vm._l((tag.children),function(child){return _c('v-list-item',{key:child.name},[_c('v-list-item-title',[_vm._v(_vm._s(child.name))])],1)}),1)}):_vm._e()],2),(!isLoading)?_c('div',{staticClass:"show_more",on:{"click":function($event){_vm.showMore.tags = !_vm.showMore.tags}}},[_c('v-icon',[_vm._v(_vm._s(_vm.showMore.tags ? 'mdi-minus-circle-outline' : 'mdi-plus-circle-outline'))]),_vm._v(" Show more ")],1):_vm._e()]}}],null,true)}),_c('state-indicate',{ref:"formats-indicate",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
var usePromise = ref.usePromise;
return [_c('v-list-item-group',{staticClass:"pt-10",attrs:{"multiple":"","active-class":""},model:{value:(_vm.filter.format),callback:function ($$v) {_vm.$set(_vm.filter, "format", $$v)},expression:"filter.format"}},[_c('v-list',{staticClass:"tags-list"},[_c('div',{staticClass:"tags-list-item pa-0 pb-5"},[_c('h5',[_vm._v("Format")])]),(isLoading)?_c('v-skeleton-loader',{attrs:{"type":"table-heading@6"}}):_vm._e(),(!isLoading)?_vm._l((_vm.formats.slice(0, _vm.showMore.formats ? _vm.formats.length : 6)),function(format,i){return _c('v-list-item',{key:i,staticClass:"tags-list-item pa-0",attrs:{"value":format},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"pl-2 mr-3"},[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-title',{staticClass:"font-weight-medium",domProps:{"textContent":_vm._s(format)}})]}}],null,true)})}):_vm._e()],2),(!isLoading)?_c('div',{staticClass:"show_more",on:{"click":function($event){_vm.showMore.formats = !_vm.showMore.formats}}},[_c('v-icon',[_vm._v(_vm._s(_vm.showMore.formats ? 'mdi-minus-circle-outline' : 'mdi-plus-circle-outline'))]),_vm._v(" Show more ")],1):_vm._e()],1)]}}],null,true)}),_c('state-indicate',{ref:"authors-indicate",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
var usePromise = ref.usePromise;
return [_c('v-list-item-group',{staticClass:"pt-10",attrs:{"multiple":"","active-class":""},model:{value:(_vm.filter.author),callback:function ($$v) {_vm.$set(_vm.filter, "author", $$v)},expression:"filter.author"}},[_c('v-list',{staticClass:"tags-list"},[_c('div',{staticClass:"tags-list-item pa-0 pb-5"},[_c('h5',[_vm._v("Author")])]),(isLoading)?_c('v-skeleton-loader',{attrs:{"type":"table-heading@6"}}):_vm._e(),(!isLoading)?_vm._l((_vm.authors.slice(0, _vm.showMore.authors ? _vm.authors.length : 6)),function(author,i){return _c('v-list-item',{key:i,staticClass:"tags-list-item pa-0",attrs:{"value":author},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"pl-2 mr-3"},[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-title',{staticClass:"font-weight-medium",domProps:{"textContent":_vm._s(author)}})]}}],null,true)})}):_vm._e()],2),(!isLoading)?_c('div',{staticClass:"show_more",on:{"click":function($event){_vm.showMore.authors = !_vm.showMore.authors}}},[_c('v-icon',[_vm._v(_vm._s(_vm.showMore.authors ? 'mdi-minus-circle-outline' : 'mdi-plus-circle-outline'))]),_vm._v(" Show more ")],1):_vm._e()],1)]}}],null,true)}),_c('state-indicate',{ref:"languages-indicate",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
var usePromise = ref.usePromise;
return [_c('v-list-item-group',{staticClass:"pt-10",attrs:{"multiple":"","active-class":""},model:{value:(_vm.filter.language),callback:function ($$v) {_vm.$set(_vm.filter, "language", $$v)},expression:"filter.language"}},[_c('v-list',{staticClass:"tags-list"},[_c('div',{staticClass:"tags-list-item pa-0 pb-5"},[_c('h5',[_vm._v("Language")])]),(isLoading)?_c('v-skeleton-loader',{attrs:{"type":"table-heading@6"}}):_vm._e(),(!isLoading)?_vm._l((_vm.languages.slice(0, _vm.showMore.languages ? _vm.languages.length : 6)),function(language,i){return _c('v-list-item',{key:i,staticClass:"tags-list-item pa-0",attrs:{"value":language},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"pl-2 mr-3"},[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-title',{staticClass:"font-weight-medium",domProps:{"textContent":_vm._s(language)}})]}}],null,true)})}):_vm._e()],2),(!isLoading)?_c('div',{staticClass:"show_more",on:{"click":function($event){_vm.showMore.languages = !_vm.showMore.languages}}},[_c('v-icon',[_vm._v(_vm._s(_vm.showMore.languages ? 'mdi-minus-circle-outline' : 'mdi-plus-circle-outline'))]),_vm._v(" Show more ")],1):_vm._e()],1)]}}],null,true)})],1),_c('v-col',{attrs:{"md":"8","lg":"9","sm":"8","cols":"12"}},[_c('v-progress-linear',{style:({opacity: isLoading ? 1 : 0}),attrs:{"indeterminate":""}}),_c('v-row',[_vm._l((12),function(i){return (isLoading)?_c('v-col',{key:i + Math.random(),staticClass:"py-5",attrs:{"cols":"12","lg":"3","md":"4","sm":"5"}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"card","width":"200"}})],1):_vm._e()}),_vm._l((_vm.products),function(product){return _c('v-col',{key:product.id,staticClass:"py-5",attrs:{"cols":"6","lg":"3","md":"4","sm":"6"}},[_c('product-card-slide',{attrs:{"product":product}})],1)})],2),(!isLoading)?_c('v-pagination',{attrs:{"length":_vm.meta.last_page,"circle":"","total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }